import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MembershipDto, PatientDto } from '@bemum/api-interfaces';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { AuthentificationService } from '../../core/services/authentification.service';
import { PatientsService } from '../../core/services/patients.service';

enum State {
  LOADING,
  UNSUBSCRIBED,
  WILL_UNSUBSCRIBE,
  SUBSCRIBED,
  EDIT_SUBSCRIPTION,
  EDIT_SUBSCRIPTION_SOPK,
  CREATE_SUBSCRIPTION,
}

@Component({
  selector: 'bemum-subscription-billing',
  templateUrl: './subscription-billing.component.html',
  styleUrls: ['./subscription-billing.component.scss'],
})
export class SubscriptionBillingComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input() public patient: PatientDto;

  public currentState = State.LOADING;
  public State = State;

  hasActiveSubscription = false;

  withEssentielSupplements = false;
  withEssentielCoaching = false;
  withCoachingOption = false;
  withStructurantOption = false;
  withProtecteurOption = false;
  withSopk1Month = false;
  withSopk3Months = false;
  withSopk6Months = false;
  totalOptions = 0;
  totalShipping = 6.5;

  essentialTooltip = `Vous voulez réduire votre accompagnement dans BeMum ? Nous vous proposons de commencer par annuler votre option "Spécificité coaching."`;

  isVisibleCoachingDropOutModal = false;
  isVisibleSupplementsDropOutModal = false;
  isVisibleShopifyManageSubModal = false;

  currentMembershipText = '';
  futureMembershipText = '';

  hasJustUpdatedSubscription = false;

  url = '';

  constructor(
    private patientService: PatientsService,
    private notification: NzNotificationService,
    private authentificationService: AuthentificationService
  ) {}

  /**
   * On init, check if patient has active subscription
   */
  ngOnInit(): void {
    if (this.patient) {
      this.initState();
    }
    this.url = window.location.href;
  }

  /** Reset the currentState based on the patient's membership */
  initState() {
    // Subscribe to current user to get update in membership when user changes subscription
    const sub = this.patientService.get(this.patient.id).subscribe((patient) => {
      this.patient = patient;
      this.authentificationService.currentUser = this.patient;

      if (this.patient && this.patient.currentMembership && this.patient.futureMembership) {
        const { currentMembership, futureMembership } = this.patient;

        this.currentMembershipText = this.getSubscriptionText(currentMembership);
        this.futureMembershipText = this.getSubscriptionText(futureMembership, true);

        const noCurrentSubscription = Object.keys(currentMembership).every((key) => currentMembership[key] === false);
        const noFutureSubscription = Object.keys(futureMembership).every((key) => futureMembership[key] === false);
        if (!noCurrentSubscription && !noFutureSubscription) {
          this.currentState = State.SUBSCRIBED;
          this.hasActiveSubscription = true;
        }
        if (!noCurrentSubscription && noFutureSubscription) {
          this.currentState = State.WILL_UNSUBSCRIBE;
          this.hasActiveSubscription = true;
        }
        if (noCurrentSubscription) this.currentState = State.UNSUBSCRIBED;

        if (currentMembership.essentialCoaching) {
          this.withEssentielCoaching = true;
        }
        if (currentMembership.essentialSupplements) {
          this.withEssentielSupplements = true;
        }
        if (currentMembership.optionCoaching) {
          this.withCoachingOption = true;
        }
        if (currentMembership.optionWeigth) {
          this.withStructurantOption = true;
        }
        if (currentMembership.option35) {
          this.withProtecteurOption = true;
        }
        if (currentMembership.sopk1Month) {
          this.withSopk1Month = true;
        }
        if (currentMembership.sopk3Months) {
          this.withSopk3Months = true;
        }
        if (currentMembership.sopk6Months) {
          this.withSopk6Months = true;
        }

        this.onUpdatedOptions();
      }
    });
    this.subscriptions.push(sub);
  }

  /** cleanup */
  ngOnDestroy() {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  setCurrentState(state: State) {
    this.currentState = state;
  }

  /**
   * After click on option checkbox,
   * update totalPrice
   */
  onUpdatedOptions() {
    let totalOptions = 0;
    if (this.withEssentielCoaching) {
      totalOptions += 30;
    }
    if (this.withEssentielSupplements) {
      totalOptions += 31.5;
    }
    if (this.withCoachingOption) {
      totalOptions += 30;
    }
    if (this.withProtecteurOption) {
      totalOptions += 19.8;
    }
    if (this.withStructurantOption) {
      totalOptions += 16.5;
    }
    if (this.withSopk1Month) {
      totalOptions = 78;
      if (this.withProtecteurOption) totalOptions += 19.8;
      if (this.withCoachingOption) totalOptions += 30;
    }
    if (this.withSopk3Months) {
      totalOptions = 199;
      if (this.withProtecteurOption) totalOptions += 59.4;
      if (this.withCoachingOption) totalOptions += 90;
    }
    if (this.withSopk6Months) {
      totalOptions = 375;
      if (this.withProtecteurOption) totalOptions += 118.8;
      if (this.withCoachingOption) totalOptions += 180;
    }
    this.totalOptions = totalOptions;
    const nothingToShip = !this.withEssentielSupplements && !this.withProtecteurOption && !this.withStructurantOption;
    this.totalShipping = totalOptions === 0 || totalOptions >= 45 || nothingToShip ? 0 : 6.5;
  }

  /**
   * If patient has an active subscription (currentMembership !== NONE),
   * it will update the existing subscription
   * Else, it will create a new one right away
   */
  doChangeSubscription(): void {
    if (this.hasActiveSubscription) {
      this.updateSubscription();
    } else {
      this.createSubscription();
    }
  }

  /**
   * After click on "SE REABONNER" AND "Valider mon achat",
   * update subscription via API call
   */
  createSubscription(): void {
    this.currentState = State.LOADING;
    const {
      withCoachingOption,
      withStructurantOption,
      withProtecteurOption,
      withEssentielCoaching,
      withEssentielSupplements,
      withSopk1Month,
      withSopk3Months,
      withSopk6Months,
    } = this;
    const options = {
      withEssentielCoaching,
      withEssentielSupplements,
      withCoachingOption,
      withStructurantOption,
      withProtecteurOption,
      withSopk1Month,
      withSopk3Months,
      withSopk6Months,
      withShipping: !!this.totalShipping,
      domainURL: this.url,
    };
    if (this.patient?.stripeCustomerId) {
      this.patientService.createCheckoutSession(this.patient.id, options).subscribe({
        next: (res) => {
          window.open(res.url, '_self').focus();
        },
        error: () => {
          this.notification.error('Erreur', 'Une erreur est survenue.');
          this.initState();
        },
      });
    } else {
      this.patientService.createShopifyCheckoutSession(this.patient.id, options).subscribe({
        next: (res) => {
          window.open(res.url, '_self').focus();
        },
        error: () => {
          this.notification.error('Erreur', 'Une erreur est survenue.');
          this.initState();
        },
      });
    }
  }

  handleSubscription() {
    // If patient is not a stripeCustomer, it is a shopify customer, then show shopify modal
    if (!this.patient.stripeCustomerId) {
      this.isVisibleShopifyManageSubModal = true;
      return;
    }

    // If patient is stripe customer with sopk offer display EDIT_SUBSCRIPTION_SOPK state
    if (this.isSOPKSubscription()) {
      this.setCurrentState(State.EDIT_SUBSCRIPTION_SOPK);
      return;
    }

    // If patient is stripe customer with normal offer (not sopk) display EDIT_SUBSCRIPTION state
    this.setCurrentState(State.EDIT_SUBSCRIPTION);
  }

  /**
   * After click on "MODIFIER MES OPTIONS" AND "Valider mon achat",
   * update subscription via API call
   */
  updateSubscription(): void {
    this.currentState = State.LOADING;
    const {
      withCoachingOption,
      withStructurantOption,
      withProtecteurOption,
      withEssentielCoaching,
      withEssentielSupplements,
      withSopk1Month,
      withSopk3Months,
      withSopk6Months,
    } = this;
    const options = {
      withEssentielCoaching,
      withEssentielSupplements,
      withCoachingOption,
      withStructurantOption,
      withProtecteurOption,
      withSopk1Month,
      withSopk3Months,
      withSopk6Months,
      withShipping: !!this.totalShipping,
    };
    if (this.patient?.stripeCustomerId) {
      this.patientService.updateSubscription(this.patient.id, options).subscribe({
        next: () => {
          this.patientService.get(this.patient.id).subscribe((patient) => {
            this.patient = patient;
            this.authentificationService.currentUser = this.patient;
            this.notification.success(
              'Succès',
              "Votre abonnement a bien été mis à jour. Les changements seront effectifs dès votre prochain mois d'abonnement."
            );
            if (!withEssentielCoaching && patient.currentMembership.essentialCoaching) {
              this.isVisibleCoachingDropOutModal = true;
            }
            if (!withEssentielSupplements && patient.currentMembership.essentialSupplements) {
              this.isVisibleSupplementsDropOutModal = true;
            }
            this.initState();
            this.hasJustUpdatedSubscription = true;
          });
        },
        error: (err) => {
          if (err.status === 423) {
            this.notification.error(
              'Erreur',
              'Pour vous assurer un accompagnement de qualité, vous ne pouvez modifier votre abonnement dans les 3 jours précédant votre prochain prélèvement.',
              { nzDuration: 10000 }
            );
          } else {
            this.notification.error(
              'Erreur',
              "La modification de votre programme BeMum a échoué. Veuillez contacter BeMum afin de débloquer la situation. Utilisez le chat ou l'adresse email hello@bemum.co."
            );
          }
          this.initState();
        },
      });
    } else {
      this.createShopifyPortalSession();
    }
  }

  /**
   * Does not work with unpaid Appstle subscription
   */
  createShopifyPortalSession(): void {
    const sub = this.patientService.createShopifyPortalSession(this.patient?.id).subscribe({
      next: (data) => {
        window.open(data.manageSubscriptionLink, '_blank');
      },
      error: (error) => {
        console.log(error);
        this.notification.error(`Une erreur est survenue`, `Veuillez réessayer un peu plus tard`);
      },
    });

    this.subscriptions.push(sub);
  }

  // Returns true if the membership is empty (no active subscription)
  isMembershipEmpty(membership: MembershipDto) {
    return Object.keys(membership).every((key) => membership[key] === false);
  }

  getSubscriptionText(membership: MembershipDto, isFuture = false) {
    if (this.isMembershipEmpty(membership)) {
      return isFuture ? 'Aucun' : 'Vous êtes désinscrite du programme BeMum.';
    }

    let subscription = '';
    if (membership.essentialSupplements) {
      subscription += ', Compléments alimentaires essentiels';
    }

    if (membership.essentialCoaching) {
      subscription += ', Coaching';
    }

    if (membership.optionCoaching) {
      subscription += ', Téléconsultation supplémentaires';
    }
    if (membership.option35) {
      subscription += ', Spécificité  + 35 ans';
    }
    if (membership.optionWeigth) {
      subscription += ', Spécificité poids / SOPK';
    }
    if (membership.sopk1Month) {
      subscription += ', Sopk 1 mois';
    }
    if (membership.sopk3Months) {
      subscription += ', Sopk 3 mois';
    }
    if (membership.sopk6Months) {
      subscription += ', Sopk 6 mois';
    }

    return subscription.slice(1);
  }
  // Détermine si le bouton "Gérer mon abonnement" doit être affiché
  shouldDisplaySubscriptionButton(): boolean {
    // Cacher le bouton si l'un des abonnements spécifiques est actif
    return !this.isSOPKSubscription();
  }

  // Vérifie si l'un des abonnements spécifiques est actif dans le currentMembership ou le futureMembership
  isSOPKSubscription(): boolean {
    const isCurrentSOPK =
      this.patient.currentMembership &&
      (this.patient.currentMembership.sopk1Month ||
        this.patient.currentMembership.sopk3Months ||
        this.patient.currentMembership.sopk6Months);

    const isFutureSOPK =
      this.patient.futureMembership &&
      (this.patient.futureMembership.sopk1Month ||
        this.patient.futureMembership.sopk3Months ||
        this.patient.futureMembership.sopk6Months);

    return isCurrentSOPK || isFutureSOPK;
  }

  onCloseModal = () => {
    this.isVisibleCoachingDropOutModal = false;
    this.isVisibleSupplementsDropOutModal = false;
    this.isVisibleShopifyManageSubModal = false;
  };
}
